/* The following block can be included in a custom.scss */
@import 'abstracts/variables/colors';

$breadcrumb-bg: $palette-xxxxxx-light;
$body-color: $palette-xxxxxx-light;
$breadcrumb-active-color: $palette-xxxxxx-light;
$input-group-addon-bg: $palette-xxxxxx-light;
$input-group-addon-border-color: $palette-xxxxxx-light;

$link-color: $palette-xxxxxx-light;
/* set the overriding variables */
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1920px,
);
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1920px,
);

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
