@import '../../../assets/sass/abstracts/mixins';
@import '../../../assets/sass/abstracts/variables/vars';

#header {
  position: fixed;
  z-index: 1100;
  width: 100%;

  @media only screen and (min-width: $break-high-resolution-desktop + 1) {
    max-width: 1920px;
  }

  &.black {
    .toggle-content {
      &[open] {
        .toggle-header {
          .toggle-label {
            color: $palette-xxxxxx-light;

            &:before,
            &:after {
              border-bottom: 0.36em solid $palette-xxxxxx-light;
            }
          }
        }
      }
    }

    .toggle {
      .toggle-header {
        color: black;

        .toggle-label {
          &:before,
          &:after {
            border-bottom: 0.36em solid $palette-dark;
          }
        }
      }
    }
  }

  .header {
    justify-content: space-between;
    display: flex;
    align-items: center;

    .header-right {
      display: flex;
      align-items: center;
    }
  }

  .field-image {
    a {
      @include respond-to(break-extra-large-desktop) {
        top: 5.8rem;
      }

      position: absolute;
      top: 24%;
      max-width: 100%;
      width: 9.667rem;
      height: 3.8rem;

      img {
        width: 100%;
        height: auto;

        &.navClose {
          display: none;
        }
      }
    }
  }

  .toggle {
    @include respond-to(break-extra-extra-large-desktop) {
      padding: 5.5rem 0rem 4.6rem 0rem;
    }
    padding: 1.9rem 0;
    position: static;

    .toggle-header {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      background: 0 0;
      color: $white;
      outline: 0;
      cursor: pointer;
      min-height: 2.5rem;
      text-align: center;
      list-style: none;
      font-weight: 600;
      font-size: 2rem;

      &:before,
      &:after {
        display: none !important;
      }

      .toggle-label {
        outline: 0;
        text-transform: uppercase;
        transition: all 0.6s cubic-bezier(0.46, -0.41, 0.3, 1.52) 0.1s;
        padding-bottom: 5.2rem;
        display: block;
        letter-spacing: 0.01rem;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 73%;
          left: 56%;
          width: 82%;
          transform: translate(-50%, -50%);
          border-bottom: 0.36em solid $white;
          transition: all 0.6s cubic-bezier(0.46, -0.41, 0.3, 1.52) 0.1s;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      & + .mainNav {
        position: absolute;
        top: 98%;
        left: 0;
        overflow: auto;
        width: 100%;
        z-index: 4;
        background-color: $palette-dark;
        height: calc(100vh - 98%);
        overflow-x: hidden;
        scrollbar-color: $palette-x-dark $palette-x-dark;
        &::-webkit-scrollbar {
          width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 0.25rem $palette-x-dark;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $palette-x-dark;
          border-radius: 1rem;
        }
        .component-content {
          animation-name: easeInOutSine;
          animation-duration: 400ms;
          display: block;

          ul {
            list-style: none;
            padding: 0;
          }
        }
      }
    }

    .toggle-content {
      &[open] {
        .toggle-header {
          .toggle-label {
            &:before {
              transform: translate(-50%, -50%) rotate(135deg);
            }

            &:after {
              transform: translate(-50%, -50%) rotate(-135deg);
            }
          }
        }
      }
    }

    .sign-up {
      border: none;
      padding-top: 0;
      margin-bottom: 5rem;

      .sign-up-details {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .signup-details-wrapper {
          @include respond-to(mobile) {
            max-width: 16rem;
          }

          max-width: 26.7rem;
        }

        .sign-up-description {
          width: 100%;
          margin-top: 0;
        }
      }

      .social-icons {
        width: 100%;
        @include respond-to(tab) {
          margin-bottom: 1rem;
        }
        a {
          &:last-child {
            margin-bottom: 0;
            margin-right: 0;
          }
        }
      }
    }
  }

  .searchBar {
    display: flex;
    align-items: center;
    margin-right: 2rem;

    .form {
      display: flex;
      align-items: center;

      .search {
        margin-right: 8px;

        .search-img {
          width: 24px;
          height: 24px;
        }
      }

      .search-btn {
        margin-left: 8px;

        .default-button {
          cursor: pointer;
        }
      }
    }
  }

  .page-list {
    width: 100%;
    position: relative;

    > .component-content {
      .items {
        background-color: transparent;
        border-radius: 0;
        position: relative;

        .item {
          margin: 0;
          border: none;
          margin-bottom: 1.5rem;

          &.active {
            .subnav-container {
              left: -1rem;
              display: block;
            }

            .main-nav-item {
              color: $white;
              text-decoration: none;
            }
          }

          .main-nav-item {
            display: inline-block;
            font-size: 3.6rem;
            color: $grey;
            font-weight: 300;
            @include font-secondary();
            height: 100%;
          }

          a {
            &:hover {
              color: $white;
              text-decoration: none;
            }
          }

          .subnav-container {
            transition: none;
            position: relative;
            display: none;
            top: 0;
            left: -100%;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background: $palette-dark;
            padding: 0 1.5rem 1.5rem;

            .subnav-link-list {
              @include font-primary();
              border-left: 0.2rem solid rgba(255, 255, 255, 0.29);

              li {
                position: relative;
                list-style: none;
                padding-bottom: 1.333rem;

                &:last-child {
                  padding-bottom: 0;
                }
              }

              a {
                color: $grey;
                font-size: 2.4rem;
                display: inline-block;
                line-height: 1.2;
                padding-left: 1.5rem;

                &:hover,
                &:focus {
                  color: $white;
                }

                &:hover ~ .field-summary {
                  top: 0;
                  opacity: 1;
                  visibility: visible;

                  &:before {
                    width: 85%;
                  }
                }
              }

              span.field-summary {
                font-size: 1.46rem;
                display: inline-block;
                color: $white;
                vertical-align: top;
                position: absolute;
                max-width: 50%;
                right: 0;
                top: -0.5rem;
                opacity: 0;
                visibility: hidden;

                @include transition(all ease 0.3s);

                @media only screen and (max-width: 767px) {
                  transition: none;
                  position: relative;
                  display: none !important;
                  right: 0;
                  max-width: 100%;
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .toggle {
    .sign-up {
      border: none;
      padding-top: 0;
    }
  }

  //Fixed Header css
  &.fixed-header {
    padding: 3rem 0;
    position: fixed;
  }
}

//min-width: 768px
@include respond-to(tab) {
  #header {
    .toggle {
      .sign-up {
        display: flex;
        flex-wrap: wrap;
        padding-left: 2rem;
        margin-top: -2.333rem;
        margin-bottom: 0rem;

        .sign-up-details {
          width: 25rem;
        }

        .social-icons {
          width: calc(100% - 25rem);
          margin-top: -2.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          a {
            margin-right: 0;
            margin-bottom: 1.4rem;
          }
        }
      }
    }

    .page-list {
      > .component-content {
        ul {
          padding-inline-start: 0rem;

          .main-nav-item {
            display: block;
            font-size: 2.8rem;
            color: $grey;
            font-weight: 300;
            @include font-secondary();
          }
        }

        .items {
          max-width: 41.667%;
          border-right: 1px solid rgba(255, 255, 255, 0.29);

          .item {
            margin: 0.388rem 0;
            left: 0;

            &.active {
              .subnav-container {
                left: calc(100% + 2rem);
                visibility: visible;
                opacity: 1;
                width: 135%;
              }
            }

            .subnav-container {
              left: 0;
              display: block;
              width: 130%;
              height: auto;
              opacity: 0;
              position: absolute;
              visibility: hidden;
              padding: 0 1.5rem 1.5rem;

              .subnav-link-list {
                border: none;
                overflow-y: auto;
                overflow-x: hidden;
                height: 25rem;
                scrollbar-color: $palette-yellow $palette-x-dark;
                &::-webkit-scrollbar {
                  width: 0.5rem;
                }

                &::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 0.25rem $palette-x-dark;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: $palette-yellow;
                  border-radius: 1rem;
                }

                ul {
                  height: 100%;

                  li {
                    a {
                      font-size: 1.867rem;
                      line-height: 1.2;
                      padding-left: 0;
                    }
                  }
                }

                span.field-summary {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

//min-width: 992px
@include respond-to(desktop) {
  #header {
    .toggle {
      .sign-up {
        padding-left: 2rem;
        padding-top: 0;

        .sign-up-details {
          width: 39rem;
          .signup-details-wrapper {
            width: 100%;
            max-width: 100%;
            display: flex;
            align-items: flex-end;
          }
        }

        .social-icons {
          width: calc(100% - 39rem);
          margin-top: -2.5rem;
        }
      }
    }

    .page-list {
      > .component-content {
        ul {
          padding-inline-start: 0rem;
        }

        .items {
          max-width: 41.667%;

          .item {
            .main-nav-item {
              font-size: 3.2rem;
            }

            .subnav-container {
              display: block;
              position: absolute;
              padding: 0.4rem 0.667rem;

              span.field-summary {
                display: block;
                left: 29rem;
              }
            }

            &.active {
              .subnav-container {
                .subnav-link-list {
                  li {
                    a {
                      width: 30%;
                    }
                  }

                  span.field-summary {
                    display: block;
                    width: 35%;
                    left: auto;
                    padding-bottom: 0.5rem;
                    padding-right: 0.5rem;

                    &:before {
                      @include transition(all ease 0.3s);
                      content: '';
                      position: absolute;
                      top: 1rem;
                      opacity: 0.4;
                      height: 0.1rem;
                      background: $white;
                      left: -100%;
                      width: 85%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.nav-bar-search {
  border-radius: 1.733rem;
  display: flex;
  justify-content: center;
  padding: 0.3rem 1rem;
  border: 1px solid $grey;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 16px;
  margin-right: 2rem;
  gap: 0.5rem;
  max-width: 22rem;
  background-color: white;
  .search {
    display: flex;
    justify-content: center;
    cursor: pointer;
    .search-img {
      display: block;
      object-fit: contain;
      width: 100%;
  
    }
    .search-img-white {
      display: none;
      object-fit: contain;
      width: 100%;
  
    }
  }
  
  .form-control {
    border: none;
    display: block;
    font-size: 1.5rem;
  }
  .form-control:focus {
    box-shadow: none !important;
  }
}

@media screen and (max-width: 767px) {
  .nav-bar-search {
    border: none;
    margin-top: 4.5rem;
    background-color: transparent;
    .search {
      height: 3rem;
      width: 3rem;
      .search {
        display: flex;
        justify-content: center;
        cursor: pointer;
        .search-img {
          display: none;
          object-fit: contain;
          width: 100%;
      
        }
        .search-img-white {
          display: block;
          object-fit: contain;
          width: 100%;
      
        }
      }
    }
    .form-control {
      display: none;
    }
    .form-control:focus {
    }
  }
}

@include respond-to(break-extra-large-desktop) {
  #header {
    .page-list {
      > .component-content {
        .items {
          .item {
            .main-nav-item {
              font-size: 3.7rem;
            }
          }
        }
      }
    }
  }
}

