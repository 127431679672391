//
// Variables
// --------------------------------------------------
//== Colors
$palette-xxx-dark: #000000; /*Used*/
$palette-xx-dark: #2b2b2b;
$palette-x-dark: #474747;
$palette-dark: #363636;
$palette-default: #969696;
$palette-default-light: #898989; /*Used*/
$palette-medium: #707070; /*Used*/
$palette-light: #b3b3b3;
$palette-x-light: #cccccc;
$palette-xx-light: #d6d6d6;
$palette-xxx-light: #efefef; /*Used*/
$palette-xxxx-light: #f0f0f0;
$palette-xxxxx-light: #f7f7f7;
$palette-xxxxxx-light: #ffffff; /*Used*/

$text-basic: $palette-dark !default;
$page-main: $palette-xxxxxx-light;
$toggle-header-bg: transparent;
$toggle-show-color: $palette-dark;

/*Carousel variables*/
$slider-active-bg: #ffc800;
$palette-light-yellow: #fec932;

$palette-red: #ca231d;
$palette-green: #a2ca81;
$palette-yellow: #ffc906;
$palette-orange: #c70d0d;
$dark: #000;
$grey: #7e7e7e;
$light-grey: #fbfbfb;
$light-x-grey: #ebebeb;
$orange: #ffc800;
$white: #fff;
$grey-shadow: #00000029;
$black-shadow: #00000000;
$link-text: #217ee8;
$blue-btn: #2d56df;
$whiteshade: #e9eeff;
$whiteshade-light: #ebeffc;
$black-hover: #212529;
$form-box-shadow:rgb(0 0 0 / 40%);
$number-bg-color:rgba(195,195,195,.9);
$cookie-background:rgb(0,0,0,0.7);
$paletter-dark-red:#C70D0D;
/*Border colors */

$border-primary: rgba(0, 0, 0, 0.16);
$border-secondary: rgba(112, 112, 112, 0.4);
$border-tertiary: rgb(0, 0, 0, 0.2);
