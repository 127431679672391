@import 'variables/vars';

/* breakpoints */
$break-high-resolution-desktop: 1920px;
$break-extra-large-desktop: 1400px;
$break-root-font: 1600px;
$break-large-desktop: 1200px;
$break-tab-pro: 1024px;
$break-desktop: 992px;
$break-tab: 768px;
$break-mobile: 380px;
$break-mobile-horizontal: 640px;
$break-mobile-large: 786px;
$tablet: 767px;
@mixin wrapper() {
  max-width: 960px;
  margin: 0 auto !important;
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin loading-gif() {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJibGFjayI+DQogIDxwYXRoICBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4NCiAgPHBhdGggZmlsbD0nIzFhODBiNicgZD0iTTE2IDAgQTE2IDE2IDAgMCAxIDMyIDE2IEwyOCAxNiBBMTIgMTIgMCAwIDAgMTYgNHoiPg0KICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE2IDE2IiB0bz0iMzYwIDE2IDE2IiBkdXI9IjAuOHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICA8L3BhdGg+DQo8L3N2Zz4NCg==);
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin respond-to($media) {
  @if $media==mobile {
    @media only screen and (max-width: $break-mobile) {
      @content;
    }
  } @else if $media==mobile-first {
    @media only screen and (min-width: $break-mobile) {
      @content;
    }
  } @else if $media==mobile-horizontal {
    @media only screen and (max-width: $break-mobile-horizontal - 1) {
      @content;
    }
  } @else if $media==mobile-large {
    @media only screen and (max-width: $break-mobile-large) {
      @content;
    }
  } @else if $media==tablet {
    @media only screen and (min-width: $break-tab) and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media==tablet-pro {
    @media only screen and (min-width: $break-tab) and (max-width: $break-tab-pro) and (orientation: portrait) {
      @content;
    }
  } @else if $media==tablet-high-resolution-desktop {
    @media only screen and (min-width: $break-desktop) and (max-width: $break-high-resolution-desktop - 1) {
      @content;
    }
  } @else if $media==all-mobile {
    @media only screen and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media==tab {
    @media only screen and (min-width: $break-tab) {
      @content;
    }
  } @else if $media==desktop {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  } @else if $media==large-desktop {
    @media only screen and (min-width: $break-large-desktop) {
      @content;
    }
  } @else if $media==tab-horizontal {
    @media only screen and (min-width: $break-desktop) and (max-width: $break-large-desktop) and (orientation: landscape) {
      @content;
    }
  } @else if $media==mob-horizontal {
    @media only screen and (min-width: $break-mobile) and (max-width: $break-desktop) and (orientation: landscape) {
      @content;
    }
  } @else if $media==break-extra-large-desktop {
    @media only screen and (min-width: $break-extra-large-desktop) {
      @content;
    }
  } @else if $media==break-extra-semi-mid-desktop {
    @media only screen and (min-width: $break-extra-large-desktop) and (max-width: $break-root-font - 1) {
      @content;
    }
  } @else if $media==break-extra-mid-desktop {
    @media only screen and (min-width: $break-root-font) and (max-width: $break-high-resolution-desktop - 1) {
      @content;
    }
  } @else if $media==break-extra-extra-large-desktop {
    @media only screen and (min-width: $break-high-resolution-desktop) {
      @content;
    }
  } @else if $media==break-root-font {
    @media only screen and (min-width: $break-root-font) {
      @content;
    }
  }
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) ==false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin basic-padding($values: 15px) {
  $length: length($values);
  $top: nth($values, 1);
  $right: $top;
  $bottom: $top;
  $left: $top;
  @if $length==2 {
    $right: nth($values, 2);
    $left: $right;
  } @else if $length==3 {
    $right: nth($values, 2);
    $bottom: nth($values, 3);
    $left: $right;
  } @else if $length>=4 {
    $right: nth($values, 2);
    $bottom: nth($values, 3);
    $left: nth($values, 4);
  }
  padding: $top $right $bottom $left;
}

@mixin font-primary() {
  font-family: 'Montserrat', sans-serif;
}

@mixin font-secondary() {
  font-family: 'Source Serif Pro', serif;
}

// Transition all
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

/*Button Arrow Common Mixin*/
@mixin arrow-right() {
  height: calc(94% - 2.2rem);
  background-size: 1rem;
  padding: 0 4rem;
  background-repeat: no-repeat;
  @include respond-to(tab) {
    padding: 0 1.5rem;
  }
  @include respond-to(desktop) {
    padding: 0 2.131rem 0 2.318rem;
  }
}

@mixin arrow-down() {
  height: calc(94% - 2.2rem);
  background-size: 1.6rem;
  padding: 0 4rem;
  background-repeat: no-repeat;
}
/*Button Arrow Light*/
@mixin button-arrow-right-light() {
  background-image: url(../../images/arrow-white-right.svg);
  @include arrow-right();
}
@mixin button-arrow-down-light() {
  background-image: url(../../images/arrow-white-down.svg);
  @include arrow-down();
}
/*Button Arrow Down*/
@mixin button-arrow-right-dark() {
  background-image: url(../../images/arrow-black-right.svg);
  @include arrow-right();
}
@mixin button-arrow-down-dark() {
  background-image: url(../../images/arrow-black-down.svg);
  @include arrow-down();
}

@mixin footer-separator() {
  border-top: 2px solid rgba($palette-xxxxxx-light, 0.35);
  @include respond-to(large-desktop) {
    border-top: 0;
  }
}
// Transform translate
@mixin translateX($translateX) {
  -moz-transform: translateX($translateX);
  -o-transform: translateX($translateX);
  -webkit-transform: translate($translateX);
  transform: translateX($translateX);
}

@mixin translateY($translateY) {
  -moz-transform: translateY($translateY);
  -o-transform: translateY($translateY);
  -webkit-transform: translateY($translateY);
  transform: translateY($translateY);
}

@mixin translate($translateX, $translateY) {
  -moz-transform: translate($translateX, $translateY);
  -o-transform: translate($translateX, $translateY);
  -webkit-transform: translate($translateX, $translateY);
  transform: translate($translateX, $translateY);
}
