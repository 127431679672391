@import '../../../assets/sass/abstracts/mixins';
@import '../../../assets/sass/abstracts/variables/vars';

.footer-wrapper {
  .sign-up {
    .sign-up-details {
      width: 100%;

      .signup-details-wrapper {
        position: relative;

        .signUpArrow {
          padding: 0 0 0 2rem;
          position: absolute;
          right: -8.5rem;
          top: -5rem;
          width: 11rem;
          @include respond-to(mobile) {
            top: -1rem;
            width: 9rem;
            right: -7rem;
          }
          @media only screen and (min-width: $break-mobile) and (max-width: $break-tab) {
            top: -1rem;
            width: 10rem;
            right: -5rem;
          }
          @include respond-to(tab) {
            top: -5rem;
            right: -8rem;
          }
        }
        @include respond-to(tab) {
          max-width: 46.7rem;
        }
        @include respond-to(mobile) {
          max-width: 26rem;
        }

        max-width: 36.7rem;
        @media only screen and (min-width: $break-mobile) and (max-width: $break-tab - 1) {
          max-width: 29.7rem;
        }
      }

      .sign-up-description {
        width: 100%;
        margin-bottom: 4.2rem;
      }
    }

    .social-icons {
      width: 100%;
    }

    @include respond-to(large-desktop) {
      .social-icons {
        width: 25%;
      }
      .sign-up-details {
        width: 75%;
        .signup-details-wrapper {
          .signUpArrow {
            top: -6rem;
            right: -9rem;
          }
        }
      }
    }
    @include respond-to(desktop) {
      .sign-up-details {
        .signup-details-wrapper {
          .signUpArrow {
            top: -6rem;
            right: -8rem;
          }
        }
      }
    }
  }
}

.sign-up {
  @include footer-separator();
  margin: 3.8rem 0 0;
  padding: 7.3rem 0 0;
  color: $palette-yellow;
  font-size: 2.4rem;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;

  .signUpCta {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: $palette-yellow;
    }
  }

  .signup-details-wrapper {
    position: relative;

    .signUpArrow {
      width: 10rem;
      padding: 0 0 0 2rem;
      right: -8rem;
      top: -5rem;
      position: absolute;
      @include respond-to(mobile) {
        top: -1rem;
        right: -8rem;
      }
      @include respond-to(tab) {
        width: unset;
        height: unset;
      }
    }

    @include respond-to(mobile) {
      max-width: 16rem;
    }

    max-width: 25rem;
  }

  .sign-up-details {
    position: relative;
    width: 50%;

    .sign-up-description {
      width: 100%;
      margin-bottom: 4.2rem;
    }
  }

  .sign-up-description {
    color: $palette-xxxxxx-light;
    margin: 2rem 0 6.2rem;
    font-weight: normal;
  }

  .social-icons {
    width: 50%;
    text-align: center;

    a {
      margin-right: 2rem;
      border-radius: 50%;
      border: 2px solid transparent;
      @include transition(border ease-in-out 0.3s);

      img {
        width: auto;
        vertical-align: text-top;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        @include respond-to(tab) {
          border: 2px solid $palette-xxxxxx-light;
          display: flex;
        }
      }
    }
  }

  @include respond-to(large-desktop) {
    padding-top: 11rem;
    font-size: 1.867rem;

    .signup-details-wrapper {
      .signUpArrow {
        right: -8rem;
        width: 11rem;
        top: -6rem;
      }
    }

    .sign-up-description {
      margin: 1.067rem 0 0;
    }

    .social-icons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      a {
        margin-right: 0;
        margin-bottom: 1.4rem;

        img {
          width: auto;
          height: auto;
        }
      }
    }
  }

  @include respond-to(large-desktop) {
    padding-left: 7.333rem;
  }

  @include respond-to(break-root-font) {
    .signup-details-wrapper {
      .signUpArrow {
        right: -6rem;
        top: -6rem;
      }
    }
  }
}

.modal {
  z-index: 9999;

  @include respond-to(large-desktop) {
    .modal-lg {
      max-width: 90rem;
    }
  }

  @include respond-to(break-extra-extra-large-desktop) {
    .modal-lg {
      max-width: 91rem;
    }
  }

  .modal-content {
    border-radius: 0.8rem;
    padding: 0rem;
  }

  .modal-header {
    justify-content: flex-end;
    border-bottom: unset;
    border: 1.467rem;
    padding: 0;

    .btn-close {
      box-sizing: content-box;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25em;
      color: $dark;
      background: transparent url('../../../assets/images/close.svg') no-repeat;
      background-size: 1.5rem 1.5rem;
      border: 0;
      position: absolute;
      top: 2.333rem;
      right: 2.333rem;
      z-index: 9;
    }
  }

  .modal-body {
    padding: 0;

    .splitter {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .form-wrapper.hide-shadow {
    .formContactUS {
      width: 100%;
      padding: 0;
    }
  }

  .keyarea-image {
    padding-left: 4rem;
    padding-right: 0rem;
  }

  .containerSpacer.splitter {
    padding-top: 3.333rem;

    .area-description {
      padding: 0;
      padding-top: 3.333rem;
      padding-bottom: 2.2rem;

      .textDetails {
        padding-top: 0;
        padding-bottom: 3.333rem;

        .field {
          padding-top: 0;
        }

        .container {
          padding: 0 5.933rem;
        }

        .summary {
          ul,
          ol {
            list-style: none;
            margin-left: -1rem;
            margin-bottom: 0;
            padding-left: 4rem;

            li {
              margin-bottom: 2.333rem;
              font-size: 1.6rem;
              line-height: 1.867rem;

              &::before {
                content: '';
                background-image: url('../../../assets/images/triangle-icon.svg');
                background-size: 0.667rem 0.667rem;
                width: 0.667rem;
                height: 0.667rem;
                position: absolute;
                left: 1rem;
                margin-top: 0.6rem;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          h2,
          h3 {
            font-size: 2.667rem;
            color: $dark;
            line-height: 5.333rem;
            margin-bottom: 3.333rem;
          }
        }
      }

      .client {
        .container {
          padding-left: 6.167rem;
        }

        .logoList {
          margin-top: 0;
          text-align: center;
        }
      }
    }
    @include respond-to(mobile) {
      padding-top: 1rem;
      padding-bottom: 0;
    }

    @include respond-to(tab) {
      .area-description {
        padding-top: 0rem;

        .client {
          .logoList {
            text-align: left;
          }
        }
      }
    }
  }

  .keyarea-image {
    .formContactUS {
      .form-title {
        .title {
          font-size: 2.667rem;
          line-height: 5.333rem;
          color: $dark;
          margin-bottom: 3.333rem;
          @include respond-to(mobile) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
